<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer title="历史记录" width="640" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div>
                <a-timeline>
                    <a-timeline-item v-for="(item,key) in log_list.list" :key="key" :color="colorStatus(item)">
                        <p>{{item.title}}</p>
                        <!--                        <p v-for="(i,k) in item.content" :key="k">{{ i }}</p>-->
                        <p>处理人：{{ item.danda_name }}</p>
                        <p v-if="item.title == '校验不通过' || item.title == '撤销校验' || item.title == '审核不通过'">原因：{{item.remark}}</p>
                        <p>处理时间：{{ item.create_time }}</p>
                    </a-timeline-item>
                    <a-timeline-item v-if="log_list.source_record_create">
                        <p>{{log_list.source_record_create.title}}</p>
                        <p>创建人：{{ log_list.source_record_create.danda_name }}</p>
                        <p>创建时间：{{ log_list.source_record_create.create_time }}</p>
                    </a-timeline-item>
                </a-timeline>
            </div>
        </a-drawer>
    </div>
</template>
<script>
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'edit_time', 'edit_info'],
        data: () => ({
            log_list: {
                list: [],
                content: false,
            },
            history_i: [],
        }),
        watch: {
            edit_time() {
                this.mountedDo()
            },
        },
        mounted() {
            this.mountedDo()
        },
        methods: {
            //icon颜色
            colorStatus(item) {
                if (item.title === '校验不通过' || item.title === '审核不通过' ) return 'red'
                if (item.title === '撤销校验') return 'orange'
                return 'green'
            },

            mountedDo() {
                this.history_i = JSON.parse(JSON.stringify(this.edit_info))
                this.$sa0.post({
                    url: this.$api('Get_source_record_log1'),
                    data: {
                        source_record_id: this.history_i.id,
                        type: 1
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.log_list = response.data;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })

            },

            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },

        }
    }
</script>

<style scoped>
    #components-form-demo-validate-other .dropbox{
        height: 180px;
        line-height: 1.5;
        }
</style>
